<template>
  <v-list dense rounded>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Carlos Ramirez
        </v-list-item-title>
        <v-list-item-subtitle>
          Administrador
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-2"></v-divider>
    <v-list-item link :to="{ name: 'Inicio' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Inicio</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>
          Catálogos
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Proveedores' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Proveedores</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Rubros' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Rubros</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Senior' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Senior</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Junior' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Junior</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'ListaProductos' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Lista de Productos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>
          Carga
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Campana' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Campañas</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Catalogos' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Catálogos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'RCostos' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Costos Logísticos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'Pedido' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Generar Pedido</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>
          Reportes
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'RSenior' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Senior</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'RJunior' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Junior</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link link :to="{ name: 'RProductos' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Productos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link link :to="{ name: 'ListaBultos' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Cantidad x Bultos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-list-item link :to="{ name: 'RRubros' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Rubros</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <v-list-item link link :to="{ name: 'RProveedor' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Proveedores</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: 'ROrden' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Orden</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>
          Sistema
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-power</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Salir</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "Menu",
  data: () => ({}),
};
</script>

<style>
.v-list .v-list-item--active {
  color: white !important;
  background: #34495e;
}
</style>
