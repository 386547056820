<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <Menu />
    </v-navigation-drawer>

    <v-app-bar flat dark class="main__appbard" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Sistema de Importadores</v-toolbar-title>
    </v-app-bar>

    <v-main class="main__view">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "Home",
  components: {
    Menu,
  },
  data: () => ({
    drawer: null,
  }),
};
</script>

<style>
.main__appbard {
  background: #34495e !important;
}

.main__view {
  padding: 0px !important;
}
</style>
